<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <v-container>
      <div v-if="experience">
        <div>
          <p
            class="text-h6 text-secondary"
          >
            {{ toLongDate(experience.date) }} - {{ experience.location?.name }}
          </p>
        </div>
        <p
          style="white-space: pre-wrap;"
          v-html="experience.experience"
        />
      </div>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  id: number | string;
}>();
const { t } = useI18n();
const { handleError } = useErrorStore();

const experience = ref<App.Models.Experience>();

const breadcrumbs = computed(() => [{
  title: t('experience.title', 2),
  to: '/experiences',
}, {
  title: experience.value?.title ?? t('states.loading'),
}]);

const { toLongDate } = useDate();

async function loadData() {
  try {
    const response = await axios.get(`/api/experiences/${props.id}?inc[]=location`);
    experience.value = response.data.data;
  }
  catch (error) {
    handleError(error, t('experience.cannot_load'));
  }
}

loadData();
</script>
