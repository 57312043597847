<template>
  <v-data-table-virtual
    :items="shifts"
    :headers="headers"
    :sort-by="[{ key: 'date', order: 'desc' }, { key: 'begins', order: 'asc' }]"
  />
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  userId: string | number;
}>();

const { t } = useI18n();

const { showSnackMessage } = useErrorStore();

const shifts = ref([]);
const cancellationReasons = ref([]);

const headers = [
  {
    title: t('global.status'),
    key: 'pivot.status',
    value: (item) => t(`shift.status.${item.pivot.status}`),
  },
  {
    title: t('location.title'),
    key: 'location.name',
  },
  {
    title: t('date.title'),
    key: 'date',
  },
  {
    title: t('date.start_time'),
    key: 'begins',
  },
  {
    title: t('date.ends'),
    key: 'ends',
  },
  {
    title: t('cancellation_reason.title'),
    key: 'pivot.cancellation_reason_id',
    value: (item) => cancellationReasons.value.find((a) => a.id === item.pivot.cancellation_reason_id)?.reason ?? '',
  },
];

async function loadItems() {
  try {
    const response = await axios.get(`/api/users/${props.userId}?inc[]=shifts&inc[]=shifts.location`);
    shifts.value = response.data.data.shifts.filter((shift) => (shift.pivot.status === 'cancelled' || shift.pivot.status === 'assigned'));
    cancellationReasons.value = (await axios.get('/api/shiftcancellationreasons?per_page=1000')).data.data;
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('errors.cannot_load'));
  }
}

onMounted(() => {
  loadItems();
});
</script>
