<template>
  <v-timeline
    side="end"
  >
    <v-timeline-item
      v-for="item in activityLog"
      :key="item.id"
      dot-color="primary"
      size="small"
    >
      <template #opposite>
        <div class="text-body-1">
          {{ toDateTime(item.created_at) }}
        </div>
      </template>

      <div class="d-flex flex-column text-body-1">
        <span>{{ item.description }}</span>
        <div
          v-if="item.causer !== '-'"
          class="text-caption"
        >
          {{ item.causer }}
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelActivityLogRoute: string;
}>();

const { t } = useI18n();

const { handleError } = useErrorStore();
const { toDateTime } = useDate();

const activityLog = ref<any>([]);
const loadingCount = ref(0);

async function loadActivityLog() {
  loadingCount.value += 1;
  try {
    const response = await axios.get(props.modelActivityLogRoute);
    activityLog.value = response.data.data;
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

loadActivityLog();
</script>
