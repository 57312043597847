<template>
  <article class="mb-3">
    <global-card
      :heading-color="color"
      :heading-text-color="headingTextColor"
      :closable="closable"
      @close="emit('close')"
    >
      <template
        #title
      >
        <div
          class="d-flex text-left text-wrap"
        >
          <v-icon
            :icon="`fas fa-${article.icon}`"
            class="me-2"
            size="sm"
          />
          <span
            :style="{ lineHeight: '1.6rem' }"
          >
            {{ article.title }}
          </span>
        </div>
      </template>
      <p
        class="tiptap text-font mt-2"
        v-html="article.message"
      />
      <template #actions>
        <v-spacer />
        <div class="text-font">
          {{ toLongDateWithDay(article?.publication_date ?? undefined) }}
        </div>
      </template>
    </global-card>
  </article>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  article: App.Models.News;
  featured?: boolean;
  closable?: boolean;
}>(), {
  featured: false,
  closable: false,
});

const emit = defineEmits(['close']);

const { toLongDateWithDay } = useDate();

const color = computed(() => (props.featured ? 'primary' : 'primary-lighten-1'));
const headingTextColor = computed(() => (props.featured ? 'white' : 'primary-darken-1'));
</script>
