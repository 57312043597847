<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <password-input
      v-model="currentPassword"
      name="current_password"
      :label="t('password.old')"
    />
    <password-input
      v-model="newPassword"
      :label="t('password.new')"
      name="new_password"
    />
    <password-input
      v-model="newPasswordConfirmation"
      :label="t('password.confirm_new_password')"
      name="new_password_confirmation"
    />
    <v-btn
      color="primary"
      :text="t('password.change')"
      @click="submitForm"
    />
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PasswordInput from './PasswordInput.vue';

const { handleError } = useErrorStore();
const { t } = useI18n();

const { showSnackMessage } = useErrorStore();

const breadcrumbs = [{ title: t('password.change') }];

const newPassword = ref('');
const currentPassword = ref('');
const newPasswordConfirmation = ref('');

async function submitForm() {
  try {
    await axios.post('/api/changepassword', {
      current_password: currentPassword.value,
      new_password: newPassword.value,
      new_password_confirmation: newPasswordConfirmation.value,
    });
    showSnackMessage(t('password.change_successfully'), 'success');
  }
  catch (e) {
    handleError(e, t('password.change_failed'));
  }
}
</script>
