<template>
  <smpw-map
    ref="map"
    map-type-id="roadmap"
    :style="`height: ${mapHeight}px`"
    :center="{ lat: getConfig('smpw.city_centre_lat'), lng: getConfig('smpw.city_centre_lng') }"
    :zoom="15"
    @loaded="executeAutoZoom"
  >
    <smpw-map-marker
      v-for="(m, index) in locationMarkers"
      :key="index"
      :position="m.position"
      :title="m.title"
      :marker-image-url="markerImage(m.type)"
      @click="toggleInfoWindow(m, index)"
    />
    <smpw-map-marker
      v-for="(m, index) in storeMarkers"
      :key="index"
      :position="m.position"
      :title="m.title"
      :marker-image-url="markerImage('store')"
      @click="toggleInfoWindow(m, index + locationMarkers.length)"
    />
    <info-window
      v-if="infoWinOpen"
      :options="infoOptions"
      @closeclick="infoWinOpen = false"
    >
      <span v-html="infoContent" />
    </info-window>
  </smpw-map>
</template>

<script setup lang="ts">
import SmpwMap from '@/components/globals/SmpwMap.vue';
import SmpwMapMarker from '@/components/globals/SmpwMapMarker.vue';
import { useConfig } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { InfoWindow } from 'vue3-google-map';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { getConfig } = useConfig();
const { showSnackMessage } = useErrorStore();

const mapHeight = computed(() => (window.innerHeight - 170 - (window.innerHeight * 0.04)));

const storeImg = document.createElement('img');
storeImg.src = '/images/mapmarkers/pin_store.png';
storeImg.style.maxHeight = '48px';

const locationMarkers = ref<Array<any>>([]);
const storeMarkers = ref<Array<any>>([]);

const infoWinOpen = ref(false);
const currentInfoWinMarker = ref<any>();
const infoContent = ref<string>('');
const infoOptions = ref({
  position: {
    lat: getConfig('smpw.city_centre_lat'),
    lng: getConfig('smpw.city_centre_lng'),
  },
  pixelOffset: {
    width: 0,
    height: -35,
  },
});

function markerImage(type) {
  if (type === 'store') {
    return '/images/mapmarkers/pin_store.png';
  }

  return `/images/mapmarkers/pin_${type}.png`;
}
// Reference to the google map object.
const map = ref();

async function executeAutoZoom() {
  await new Promise(r => setTimeout(r, 200));
  if (!map.value) {
    return;
  }
  // @ts-ignore
  const bounds = new google.maps.LatLngBounds();
  storeMarkers.value.forEach((m) => {
    bounds.extend(m.position);
  });
  locationMarkers.value.forEach((m) => {
    bounds.extend(m.position);
  });
  map.value.mapRef.map.fitBounds(bounds);
}

async function fetchLocationMarkers() {
  try {
    const response = await axios.get('/api/locations', { params: { active: true, inc: ['markers'] } });
    response.data.data.forEach((location) => {
      locationMarkers.value.push(...location.markers.map((marker) => {
        marker.location_name = location.name;
        marker.location_url = `/locations/${location.id}`;
        return marker;
      }));
    });
    executeAutoZoom();
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('location.could_not_load_markers'));
  }
}
async function fetchStoreMarkers() {
  try {
    const response = await axios.get('/api/stores', { params: { active: true } });
    storeMarkers.value = response.data.data.map((store) => ({
      location_name: store.name,
      location_url: `/api/stores/${store.id}`,
      position: {
        lat: Number.parseFloat(store.lat),
        lng: Number.parseFloat(store.lng),
      },
      type_name: t('location.literature_store'),
    }));
    executeAutoZoom();
  }
  catch (error) {
    storeMarkers.value = [];
    console.error(error);
  }
}

function getInfoWindowContent(marker): string {
  let content = `<div class="m-2"><span style="font-weight: bold;">${t('location.title')}: </span>
          ${marker.location_name}
        </div>
        <div class="m-2 text-font"><span style="font-weight: bold;">${t('location.type')}: </span>
          ${marker.type_name}
        </div>`;
  if (marker.description) {
    content
          += `<div class="m-2"><span style="font-weight: bold;">${t('global.description')}: </span>
            ${marker.description}
          </div>`;
  }
  return (
    `${content}
         <div class="m-2">
            <a href="${marker.location_url}" class="btn btn-primary btn-sm" role="button" aria-disabled="true">${t('actions.show_details')}</a>
         </div>`
  );
}

function toggleInfoWindow(marker, idx) {
  // Check type of marker.
  infoOptions.value.position = marker.position;
  infoContent.value = getInfoWindowContent(marker);
  // Check type of IDX, filter through.
  if (currentInfoWinMarker.value === idx) {
    infoWinOpen.value = !infoWinOpen.value;
  }
  else {
    infoWinOpen.value = true;
    currentInfoWinMarker.value = idx;
  }
}

onMounted(() => {
  fetchLocationMarkers();
  fetchStoreMarkers();
});

executeAutoZoom();
</script>
