<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/before-shifts/my-tasks"
    />
    <task-list />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import TaskList from './TaskList.vue';

const { t } = useI18n();
const breadcrumbs = computed(() => [{ title: t('task.mine') }]);
</script>
