<template>
  <div>
    <div class="text-h5">
      {{ t('current_needs.statistics_title') }}
    </div>
    <div class="text-body-1">
      {{ t('current_needs.statistics_description') }}
    </div>
    <v-table>
      <thead>
        <tr>
          <th class="width-60">
            {{ t('global.timeband') }}
          </th>
          <th
            v-for="(location, index) in bandLocations"
            :key="index"
            class="text-break width-60"
          >
            {{ location }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(timeband, index) in bandData"
          :key="timeband"
        >
          <td class="width-60">
            {{ index }}
          </td>
          <td
            v-for="location in bandLocations"
            :key="location"
            class="width-60"
          >
            <template v-if="findBandShift(location, index)">
              <div class="d-flex flex-row">
                <v-icon
                  icon="fas fas fa-user-tie"
                  size="x-small"
                />
                <v-progress-linear
                  class="my-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findBandShift(location, index)?.assigned_captain_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findBandShift(location, index)?.assigned_captain_percentage ?? 0) + parseFloat(findBandShift(location, index)?.available_captain_percentage ?? 0)"
                  rounded
                />
              </div>
              <div class="d-flex flex-row">
                <v-icon
                  icon="fas fas fa-users"
                  size="x-small"
                />
                <v-progress-linear
                  class="mb-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findBandShift(location, index)?.assigned_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findBandShift(location, index)?.assigned_percentage ?? 0) + parseFloat(findBandShift(location, index)?.available_percentage ?? 0)"
                  rounded
                />
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-table>
      <thead>
        <tr>
          <th class="width-60">
            {{ t('date.day') }}
          </th>
          <th
            v-for="(location, index) in dayLocations"
            :key="index"
            class="width-60 text-break"
          >
            {{ location }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(day, index) in dayData"
          :key="day"
        >
          <td class="width-60">
            {{ $t(`dow.${index}`) }}
          </td>
          <td
            v-for="location in dayLocations"
            :key="location"
            class="width-60"
          >
            <template v-if="findDayShift(location, index)">
              <div
                class="d-flex flex-row"
              >
                <v-icon
                  icon="fas fas fa-user-tie"
                  size="x-small"
                />
                <v-progress-linear

                  class="my-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findDayShift(location, index)?.assigned_captain_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findDayShift(location, index)?.assigned_captain_percentage ?? 0) + parseFloat(findDayShift(location, index)?.available_captain_percentage ?? 0)"
                  rounded
                />
              </div>
              <div class="d-flex flex-row">
                <v-icon
                  icon="fas fas fa-users"
                  size="x-small"
                />
                <v-progress-linear
                  class="mb-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findDayShift(location, index)?.assigned_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findDayShift(location, index)?.assigned_percentage ?? 0) + parseFloat(findDayShift(location, index)?.available_percentage ?? 0)"
                  rounded
                />
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-btn
      v-if="can('submit-availability')"
      color="primary"
      size="large"
      class="mt-4 float-right"
      to="/mycalendar"
      :text="t('smpw.submit_availability')"
    />
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from '@/composables';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { can } = usePermissions();
const { t } = useI18n();
const barHeight = 14;

const bandLocations = ref([]);
const bandData = ref([]);

const dayLocations = ref([]);
const dayData = ref([]);

function findDayShift(location: string, day: number) {
  try {
    return dayData.value[day][location];// .find((a) => a.name === location && a.day_of_week === day);
  }
  catch {
    return null;
  }
}
function findBandShift(location: string, timeband) {
  try {
    return bandData.value[timeband][location];// .find((a) => a.name === location && a.day_of_week === day);
  }
  catch {
    return null;
  }
}
async function loadData() {
  try {
    const bandResponse = await axios.get('/api/shifts/currentNeeds/statistics/band');
    bandData.value = bandResponse.data.data;
    bandLocations.value = bandResponse.data.locations;

    const dayResponse = await axios.get('/api/shifts/currentNeeds/statistics/day');
    dayData.value = dayResponse.data.data;
    dayLocations.value = dayResponse.data.locations;
  }
  catch (error) {
    console.error(error);
  }
}

loadData();
</script>

<style scoped>
.width-60 {
  min-width: 120px !important;
}

table > tbody > tr > td:nth-child(1),
  table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 5;
    background: white;
  }
  table > thead > tr > th:nth-child(1) {
    z-index: 5;
  }
</style>
