<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <team-manager affiliated-only />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import TeamManager from './TeamManager.vue';

const { t } = useI18n();

const breadcrumbs = computed(() => [{ title: t('team.mine', 2) }]);
</script>
