<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/after-shifts/experiences"
    >
      <template #append>
        <v-spacer />
        <div
          v-if="can('submit-experience')"
          class="text-right align-top"
        >
          <v-btn
            v-if="smAndUp"
            color="secondary"
            size="large"
            prepend-icon="fas fa-plus"
            :text="t('experience.submit')"
            @click="openForm"
          />
        </div>
      </template>
    </breadcrumb-header>
    <v-btn
      v-if="!smAndUp"
      color="secondary"
      size="large"
      prepend-icon="fas fa-plus"
      :text="t('experience.submit')"
      @click="openForm"
    />
    <div v-if="!submittingExperience">
      <experience-card
        v-if="featuredExperience"
        :experience="featuredExperience"
        :featured="true"
        color="primary"
        class="my-4"
        @click="handleExperienceClick(featuredExperience)"
      />

      <v-divider />
      <div class="text-h5 my-1">
        {{ t('experience.more') }}
      </div>
      <v-divider class="mb-4" />

      <div>
        <v-text-field
          v-model.lazy="searchExperiences"
          clearable
          :label="t('global.search')"
          @update:model-value="reloadExperiences()"
          @click:clear="reloadExperiences()"
        >
          <template #prepend-inner>
            <v-icon
              icon="fas fa-search"
              size="x-small"
            />
          </template>
        </v-text-field>
      </div>

      <div class="mb-4">
        <div v-if="experiences && experiences.length">
          <v-row>
            <v-col
              v-for="experience in experiences"
              :key="experience.id"
              cols="12"
              md="6"
            >
              <experience-card
                color="primary-lighten-1"
                :experience="experience"
                :click="handleExperienceClick"
                :truncate="true"
              />
            </v-col>
          </v-row>
        </div>
        <v-alert
          v-else-if="experiences"
          :text="searchExperiences ? t('experience.no_matching_found') : t('experience.none_found')"
        />
        <div v-else>
          <div class="text-center">
            <loading-spinner />
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="submittingExperience"
      :fullscreen="xs"
      max-width="600px"
    >
      <experience-submit-form
        v-if="submittingExperience"
        @close="closeForm"
      />
    </v-dialog>
  </div>
  <v-dialog
    v-if="selectedExperience"
    v-model="showSelectedExperience"
    max-width="600px"
    :fullscreen="xs"
  >
    <experience-card
      color="primary"
      :experience="selectedExperience"
      closable
      @close="showSelectedExperience = false"
    />
  </v-dialog>
  <vuetify-data-table
    :headers="headers"
    :model-name="t('experience.title')"
    url="/api/experiences/published"
    :includes="['location']"
    :additional-actions="additionalActions"
    :edit-enabled="false"
    :delete-enabled="false"
  />
</template>

<script setup lang="ts">
import { usePermissions } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import VuetifyDataTable from '../crud/VuetifyDataTable.vue';
import TooltipButton from '../TooltipButton.vue';
import ExperienceCard from './ExperienceCard.vue';
import ExperienceSubmitForm from './ExperienceSubmitForm.vue';

const { t } = useI18n();
const headers = [
  {
    key: 'date',
    title: t('date.title'),
  },
  {
    key: 'title',
    title: t('global.title'),
  },
  {
    key: 'location_id',
    value: 'location.name',
    title: t('location.title'),
  },
  {
    key: 'actions',
    title: t('global.action', 2),
    sortable: false,
  },
];

const router = useRouter();

const additionalActions = [
  {
    component: TooltipButton,
    callback: viewExperience,
    name: t('global.read_more', 2),
    props: {
      text: t('global.read_more', 2),
      icon: 'fas fa-eye',
      color: 'primary',
      tooltip: t('global.read_more', 2),
    },
  },
];

function viewExperience(data) {
  router.push({ path: `/experiences/${data.id}` });
}

const featuredExperience = ref<App.Models.Experience>();
const breadcrumbs = computed(() => [{ title: t('experience.title', 2) }]);
const experiences = ref<[App.Models.Experience]>();
const searchExperiences = ref('');
const selectedExperience = ref<App.Models.Experience>();
const showSelectedExperience = ref(false);
const submittingExperience = ref(false);

const { smAndUp, xs } = useDisplay();
const { showSnackMessage } = useErrorStore();
const { can } = usePermissions();

async function loadFeaturedExperience() {
  try {
    const response = await axios.get('/api/experiences/featured?inc[]=location');
    featuredExperience.value = response.data.data;
  }
  catch (error) {
    console.error(error);
    showSnackMessage(t('experience.cannot_load'));
  }
}
async function loadExperiences() {
  let url = '/api/experiences/published?inc[]=location&sort=-date';
  if (searchExperiences.value)
    url += `&search=${searchExperiences.value}`;
  try {
    const response = await axios.get(url);
    experiences.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('experience.cannot_load'));
    console.error(error);
  }
}
let experienceTimeout: ReturnType<typeof setTimeout> | null = null;
async function reloadExperiences() {
  experiences.value = undefined;
  if (experienceTimeout) {
    clearTimeout(experienceTimeout);
  }
  experienceTimeout = setTimeout(() => {
    loadExperiences();
  }, 500);
}
function openForm() {
  submittingExperience.value = true;
}
function closeForm() {
  submittingExperience.value = false;
}
function handleExperienceClick(e) {
  showSelectedExperience.value = true;
  selectedExperience.value = e;
}
onMounted(() => {
  loadFeaturedExperience();
  loadExperiences();
});
</script>
