<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <div
      v-for="statistic, index in statistics"
      :key="index"
    >
      <div class="text-h5 mb-4">
        {{ statistic.description }}
      </div>
      <v-row>
        <statistic-box
          icon="fas fa-users"
          :title="t('smpw.publisher', statistic.publisher_count)"
          :value="statistic.publisher_count"
        />
        <statistic-box
          icon="fas fa-hourglass-half"
          :title="t('date.hour', statistic.hour_count)"
          :value="statistic.hour_count"
        />
      </v-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import StatisticBox from './statistics/StatisticBox.vue';

const { t } = useI18n();

const statistics = ref<any>();

const breadcrumbs = computed(() => [
  {
    title: t('global.statistics'),
  },
]);

async function loadData() {
  const response = await axios.get('/api/statistics');
  statistics.value = response.data.data.statistics;
}

loadData();
</script>
