<template>
  <v-card
    :width="width"
    :max-width="maxWidth"
    :class="fillHeight ? 'fill-height' : ''"
  >
    <v-card-title
      v-if="showHeading"
      :class="`bg-${headingColor}` "
      density="compact"
    >
      <v-row
        class="d-flex flex-row"
        align="center"
        justify="space-between"
      >
        <v-col
          v-if="hasSlot('toolbarPrepend') || closable"
          cols="1"
        >
          <slot name="toolbarPrepend" />
        </v-col>
        <v-col>
          <div
            class="text-h6 text-center font-weight-medium text-wrap"
            :class="`text-${headingTextColor}`"
          >
            <slot name="title">
              {{ title }}
            </slot>
          </div>
        </v-col>
        <v-col
          v-if="hasSlot('toolbarPrepend') || closable"
          cols="1"
        >
          <div class="d-flex flex-row justify-end">
            <v-btn
              v-if="closable"
              icon="fas fa-times"
              color="white"
              variant="text"
              @click="emit('close')"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <slot name="image" />
    <slot name="outside" />

    <v-card-text v-if="!withoutText">
      <slot />
    </v-card-text>
    <slot v-if="withoutText" />
    <v-card-actions
      v-if="withActions"
    >
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

withDefaults(defineProps<{
  title?: string;
  subTitle?: string;
  showHeading?: boolean;
  width?: string;
  maxWidth?: string;
  headingColor?: string;
  withActions?: boolean;
  fillHeight?: boolean;
  closable?: boolean;
  withoutText?: boolean;
  headingTextColor?: string;
  justifyHeading?: 'end' | 'center' | 'start' | 'space-around' | 'space-between' | 'space-evenly';
}>(), {
  showHeading: () => true,
  headingColor: () => 'primary',
  headingTextColor: () => 'white',
  withActions: () => true,
  fillHeight: () => false,
  closable: () => false,
  withoutText: () => false,
  justifyHeading: () => 'center',
});
const emit = defineEmits(['close']);
const slots = useSlots();
function hasSlot(name) {
  return !!slots[name];
};
</script>
