<template>
  <v-btn
    prepend-icon="fas fa-minus-square"
    :text="t('shift.remove')"
    color="warning"
    block
    size="default"
    @click="dialog = true"
  >
    {{ t('shift.remove') }}
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <global-card
        :title="t('shift.remove')"
        heading-color="warning"
      >
        <div class="text-body-1">
          {{ t('shift.remove_confirmation', { name: user.full_name, date: toLongDateWithDay(props.shift.date) }) }}
        </div>
        <template #actions>
          <v-btn
            :text="t('shift.dont_remove')"
            color="dark-grey"
            @click="close()"
          />
          <v-btn
            :text="t('shift.remove')"
            color="primary"
            @click="removeShift"
          />
        </template>
      </global-card>
    </v-dialog>
  </v-btn>
</template>

<script setup lang="ts">
import {
  useDate,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  user: App.Models.User;
  shift: App.ApiResources.PublisherWithShiftDetailsResource;
}>();

const emit = defineEmits(['shift:removed']);

const { t } = useI18n();

const dialog = ref(false);
const { showSnackMessage, handleError } = useErrorStore();
const { toLongDateWithDay } = useDate();

function close() {
  dialog.value = false;
}
async function removeShift() {
  try {
    await axios.patch(`/api/users/${props.user.id}/shifts/${props.shift.shift_id}`, {}, { params: { action: 'remove' } });
    showSnackMessage(t('shift.remove_success'), 'success');
    emit('shift:removed');
  }
  catch (error) {
    handleError(error, t('shift.remove_error'));
  }
  finally {
    dialog.value = false;
  }
}
</script>
