<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <div
      v-if="video"
    >
      <div class="text-subtitle-1">
        {{ video.name }}
      </div>
      <v-divider />
      <div class="text-body-1">
        {{ video.description }}
      </div>

      <div class="text-body-1">
        <video
          id="video_player"
          :src="video.signed_file_url"
          controls
        >
          {{ t('video.unsupported_browser') }}
        </video>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  id: string | number;
}>();

const { t } = useI18n();

const { handleError } = useErrorStore();
const breadcrumbs = computed(() => [{ title: t('video.title') }]);

const video = ref<App.Models.Video | null>(null);
const loadingCount = ref(0);

async function loadData() {
  loadingCount.value += 1;
  try {
    const response = await axios.get(`/api/videos/${props.id}`);
    video.value = response.data.data;
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

loadData();
</script>
