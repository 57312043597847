const routes = [
  {
    path: '/docs',
    component: async () => import('@/pages/help/publisher/DocumentationPublisherWelcome.vue'),
    name: 'Docs - Publishers',
  },
  {
    path: '/docs/admin',
    component: async () => import('@/pages/help/admin/DocumentationAdminWelcome.vue'),
    name: 'Docs - Admin',
  },
  {
    path: '/docs/admin/initial-setup',
    component: async () => import('@/pages/help/admin/DocumentationAdminInitialSetup.vue'),
    name: 'Docs - Admin - Initial Setup',
  },
  {
    path: '/docs/admin/ministry-management/shift-tags',
    component: async () => import('@/pages/help/admin/ministry-management/DocumentationAdminShiftTags.vue'),
    name: 'Docs - Admin - Shift Tags',
  },
  {
    path: '/docs/admin/ministry-management/locations',
    component: async () => import('@/pages/help/admin/ministry-management/DocumentationAdminLocations.vue'),
    name: 'Docs - Admin - Locations',
  },
  {
    path: '/docs/admin/ministry-management/stores',
    component: async () => import('@/pages/help/admin/ministry-management/DocumentationAdminStores.vue'),
    name: 'Docs - Admin - Stores',
  },
  {
    path: '/docs/admin/ministry-management/publications',
    component: async () => import('@/pages/help/admin/ministry-management/DocumentationAdminPublications.vue'),
    name: 'Docs - Admin - Publications',
  },
  {
    path: '/docs/admin/publisher-management/training',
    component: async () => import('@/pages/help/admin/publisher-management/DocumentationAdminTraining.vue'),
    name: 'Docs - Admin - Training',
  },
  {
    path: '/docs/admin/site-management/documents',
    component: async () => import('@/pages/help/admin/site-management/DocumentationAdminDocuments.vue'),
    name: 'Docs - Admin - Documents',
  },
  {
    path: '/docs/admin/site-management/contact-options',
    component: async () => import('@/pages/help/admin/site-management/DocumentationAdminContactOptions.vue'),
    name: 'Docs - Admin - Contact Options',
  },
  {
    path: '/docs/admin/site-management/cancellation-reasons',
    component: async () => import('@/pages/help/admin/site-management/DocumentationAdminCancellationReasons.vue'),
    name: 'Docs - Admin - Cancellation Reasons',
    meta: { permission: 'manage-shift-cancellation-reasons' },
  },
  {
    path: '/docs/admin/site-management/alerts',
    component: async () => import('@/pages/help/admin/site-management/DocumentationAdminAlerts.vue'),
    name: 'Docs - Admin - Alerts',
  },
  {
    path: '/docs/admin/site-management/videos',
    component: async () => import('@/pages/help/admin/site-management/DocumentationAdminVideos.vue'),
    name: 'Docs - Admin - Videos',
  },
  {
    path: '/docs/publisher/managing-shifts/my-shifts',
    component: async () => import('@/pages/help/publisher/managing-shifts/DocumentationPublisherMyShifts.vue'),
    name: 'Docs - Publishers - My Shifts',
  },
  {
    path: '/docs/publisher',
    component: async () => import('@/pages/help/publisher/DocumentationPublisherWelcome.vue'),
    name: 'Docs - Publishers',
  },
  {
    path: '/docs/publisher/starting/my-details',
    component: async () => import('@/pages/help/publisher/starting/DocumentationPublisherMyDetails.vue'),
    name: 'Docs - Publishers - My Details',
  },
  {
    path: '/docs/publisher/before-shifts/news',
    component: async () => import('@/pages/help/publisher/before-shifts/DocumentationPublisherNews.vue'),
    name: 'Docs - Publishers - News',
  },
  {
    path: '/docs/publisher/before-shifts/my-tasks',
    component: async () => import('@/pages/help/publisher/before-shifts/DocumentationPublisherMyTasks.vue'),
    name: 'Docs - Publishers - My Tasks',
  },
  {
    path: '/docs/publisher/before-shifts/current-carts',
    component: async () => import('@/pages/help/publisher/before-shifts/DocumentationPublisherCurrentCarts.vue'),
    name: 'Docs - Publishers - Current Carts',
  },
  {
    path: '/docs/publisher/before-shifts/day-overseers',
    component: async () => import('@/pages/help/publisher/before-shifts/DocumentationPublisherDayOverseers.vue'),
    name: 'Docs - Publishers - Day Overseers',
  },
  {
    path: '/docs/publisher/after-shifts/contact-us',
    component: async () => import('@/pages/help/publisher/after-shifts/DocumentationPublisherContactUs.vue'),
    name: 'Docs - Publishers - Contact Us',
  },
  {
    path: '/docs/publisher/after-shifts/experiences',
    component: async () => import('@/pages/help/publisher/after-shifts/DocumentationPublisherExperiences.vue'),
    name: 'Docs - Publishers - Experiences',
  },
  {
    path: '/docs/publisher/managing-shifts/current-needs',
    component: async () => import('@/pages/help/publisher/managing-shifts/DocumentationPublisherCurrentNeeds.vue'),
    name: 'Docs - Publishers - Current Needs',
  },
  {
    path: '/docs/publisher/managing-shifts/calendar',
    component: async () => import('@/pages/help/publisher/managing-shifts/DocumentationPublisherMyCalendar.vue'),
    name: 'Docs - Publishers - Calendar',
  },
  {
    path: '/docs/publisher/managing-shifts/availability',
    component: async () => import('@/pages/help/publisher/managing-shifts/DocumentationPublisherSubmitAvailability.vue'),
    name: 'Docs - Publishers - Availability',
  },
  {
    path: '/docs/publisher/managing-shifts/locations',
    component: async () => import('@/pages/help/publisher/managing-shifts/DocumentationPublisherLocations.vue'),
    name: 'Docs - Publishers - Locations',
  },
  {
    path: '/docs/glossary',
    component: async () => import('@/pages/help/Glossary.vue'),
    name: 'Docs - Glossary',
  },
];

export default routes;
