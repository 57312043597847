<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <search-bar
      v-if="faqs"
      :items-search-fields="['question', 'answer']"
      :child-component="FaqsList"
      :child-component-items="faqs"
      class="mb-2"
    />
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import FaqsList from '../FaqsList.vue';
import SearchBar from '../SearchBar.vue';

const { t } = useI18n();
const { handleError } = useErrorStore();

const breadcrumbs = computed(() => [{ title: t('faq.long_title') }]);
const faqs = ref<Array<App.Models.Faq>>([]);

async function loadData() {
  try {
    const response = await axios.get('/api/public/faqs');
    faqs.value = response.data;
  }
  catch (error) {
    handleError(error, t('faq.cannot_load'));
  }
}

loadData();
</script>
