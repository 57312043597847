<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/starting/my-details"
    />
    <user-details
      v-if="authUser.id"
      :user-id="authUser.id"
      :show-extended-info="false"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import UserDetails from './UserDetails.vue';

const { t } = useI18n();

const breadcrumbs = computed(() => [{ title: t('global.my_details') }]);

const { authUser } = useAuth();
</script>
