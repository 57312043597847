<template>
  <main class="mb-2">
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/before-shifts/news"
    />
    <loading-spinner
      v-if="loading"
    />
    <template v-else>
      <v-alert
        v-if="error"
        type="error"
        :text="t('news.cannot_load', 2)"
      />
      <v-alert
        v-if="!articles.length && !error"
        :text="t('news.nothing_new')"
        class="text-font"
      />
      <news-article
        v-if="featuredArticle"
        :article="featuredArticle"
        :featured="true"
        class="my-4"
      />
      <v-row>
        <v-col
          v-for="(news, index) in articles"
          :key="news.id || `fallback-${index}`"
          cols="12"
        >
          <news-article
            :article="news"
          />
        </v-col>
      </v-row>
      <news-archived-articles
        v-if="hasArchived"
        class="mt-10"
      />
    </template>
  </main>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import { useMenuStore } from '@/stores/menu';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NewsArchivedArticles from './NewsArchivedArticles.vue';

import NewsArticle from './NewsArticle.vue';

const { t } = useI18n();

const breadcrumbs = computed(() => [{
  title: t('news.current'), // Make breadcrumbs a computed variable - depends on locale
}]);

const articles = ref<App.Models.News[]>([]);
const hasArchived = ref(false);
const error = ref(false);
const loading = ref(false);

const featuredArticle = ref<App.Models.News>();

const { handleError } = useErrorStore();
const menuStore = useMenuStore();
const { unreadNewsCount } = storeToRefs(menuStore);

async function loadData() {
  error.value = false;
  loading.value = true;
  try {
    const result = await axios.get('/api/news/current');
    if (result.data.currentNews.length > 0) {
      featuredArticle.value = result.data.currentNews[0];
      articles.value = result.data.currentNews.slice(1);
    }
    hasArchived.value = result.data.archivedNewsCount > 0;
    unreadNewsCount.value = 0;
  }
  catch (e) {
    handleError(e, t('news.cannot_load', 1));
    error.value = true;
  }
  finally {
    loading.value = false;
  }
}
onMounted(() => {
  loadData();
});
</script>
